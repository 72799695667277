import React from 'react';
import './Success.css';
import rafffle from './raffle.png';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();

    const handleBackToSafety = () => {
        navigate('/'); // Navigate to the homepage or a safety resource
    };

    return (
        <div className="success-container">
            <div className="success-box">
                <img src={rafffle} alt="Icon" className="phishing-icon" />
                  <h1 className="summary-title">Congratulations!</h1>
                <p className="summary-message">
                    <span className="important-text">You have successfully joined this raffle!</span>
                </p>
                <p className="summary-submessage">
                    Thank you for your participation. Stay tuned for updates and announcements regarding the winners. We wish you the best of luck!
                </p>
                <button className="summary-button" onClick={handleBackToSafety}>
                    Back to Login
                </button>
            </div>
        </div>
    );
};

export default Success;
